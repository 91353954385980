import {
  GridItemResponsiveType,
} from "react-schematic";
/**
 * Grid items data
 */
type GridItemsData = GridItemResponsiveType & {
  text: string; title: string; id: string; name: string; pathname: string; emoji: string;
};
export const gridItemsData: GridItemsData[] = [
  {
    id: "wacklaptop",
    title: "When you've had ENOUGH of th...",  
    text: "WACK LAPTOP!",
    name: "blog",
    pathname: "/wack-laptop/",
    emoji: "😡"
   // The gridArea useful behaviour moves a grid items position in the list 
   // based upon the view size and grid items setting sm/md/lg... etc.
   // gridArea: { md: "2/2/span 1/span 1"}
  },
  {
    id: "leos",
    title: "Ve av change zer welte!",
    text: "LEAGUE OF E.O.S",
    name: "blog",
    pathname: "/league-of-eos/",
    emoji: "🏛️"
    // gridArea: { md: "2/2/span 1/span 1"} 
  },
  {
    id: "nftblacklist",
    title: "Join the blacklist here >>",
    text: "BAD SCIENTIST NFT's",
    name: "blog",
    pathname: "/nft-blacklist/",  
    emoji: "💉😱"
  },
  {
    id: "collabgabbo",
    title: "Celebrate who you partner with!",
    text: "COLLABO GABBO",
    name: "blog",
    pathname: "/collabo-gabbo/",
    emoji: "💰🤝"
  }
];


